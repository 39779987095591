import { createAction } from 'redux-actions'
import { request } from '../../api'
import { put, takeLatest } from 'redux-saga/effects'

export const REQUEST_SHOWINGS = 'Request showings'
export const RECEIVE_SHOWINGS = 'Receive showings'

export const requestShowings = createAction(REQUEST_SHOWINGS, (projectId) => ({
  projectId,
}))
export const receiveShowings = createAction(RECEIVE_SHOWINGS)

function* sagaGetShowings({ payload }) {
  try {
    const data = yield request({
      method: 'GET',
      url: `/api/user/projects/${payload.projectId}/showings`,
    })
    yield put(receiveShowings(data))
  } catch (error) {
    console.error(error)
    yield put(receiveShowings(error))
  }
}

export function* sagas() {
  yield takeLatest(requestShowings, sagaGetShowings)
}

const initialState = { status: 'idle', items: [] }

const reducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case REQUEST_SHOWINGS:
      return {
        ...state,
        status: 'loading',
      }
    case RECEIVE_SHOWINGS:
      if (error) {
        return { ...state, status: 'failed' }
      }
      return { status: 'succeeded', items: payload }
    default:
      return state
  }
}

export default reducer
