import { createAction } from 'redux-actions'
import { request } from '../../api'
import { put, takeLatest } from 'redux-saga/effects'

export const REQUEST_SEND_SIGNUP_EMAIL = 'Request send signup email'
export const RECEIVE_SEND_SIGNUP_EMAIL = 'Receive send signup email'

export const requestSendSignupEmail = createAction(REQUEST_SEND_SIGNUP_EMAIL)
export const receiveSendSignupEmail = createAction(RECEIVE_SEND_SIGNUP_EMAIL)

function* sagaSendSignupEmail({ payload }) {
  try {
    const data = yield request({
      method: 'PUT',
      url: `/api/user/send-signup-email`,
      headers: { Authorization: `Bearer ${payload.jwtToken}` },
    })
    yield put(receiveSendSignupEmail(data))
  } catch (error) {
    console.error(error)
    yield put(receiveSendSignupEmail(error))
  }
}

export function* sagas() {
  yield takeLatest(requestSendSignupEmail, sagaSendSignupEmail)
}
