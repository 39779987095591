import { connect } from 'react-redux'

import { getCurrentLocale } from '../helper'

export function toPrice(value, currency = 'SEK') {
  if (value === null || value === undefined) {
    return ''
  }

  const locale = getCurrentLocale()

  const curr = new Intl.NumberFormat(locale.code, {
    currency,
    style: 'currency',
    minimumFractionDigits: 0,
  }).format(value)
  return curr
}

const Price = ({ value, currency }) => {
  return toPrice(value, currency)
}

const mapStateToProps = ({ company }) => {
  return {
    currency: company?.currency,
  }
}

export default connect(mapStateToProps)(Price)
