/**
 * Return the `token` search param in the document's URL
 * @param {object} doc A document
 * @returns {string} The `token` search param
 */
export const getToken = (doc) => {
  return new URL(doc.location)?.searchParams?.get('token')
}

/**
 * Return the `location` search param in the document's URL
 * @param {object} doc A document
 * @returns {string} The `location` search param
 */
export const getLocation = (doc) => {
  return new URL(doc.location)?.searchParams?.get('location')
}

/**
 * Return the `referrer` search param in the document's URL
 * @param {object} doc A document
 * @returns {string} The `referrer` search param
 */
export const getReferrer = (doc) => {
  return new URL(doc.location)?.searchParams?.get('referrer')
}

/**
 * Format a value as money
 * @param {int} value An amount
 * @returns {string} The amount fomatted with currency
 */
export const formatMoney = (value) => {
  if (value) {
    return new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: 'SEK',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)
  } else {
    return ''
  }
}

/**
 * Format a value as an area
 * @param {int} value An area
 * @returns {string} The area fomatted with square meter unit
 */
export const formatArea = (value) => {
  if (value) {
    return `${value} m²`
  } else {
    return ''
  }
}

/**
 * Compares two objects based on the specified property in descending order.
 *
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @param {string} orderBy - The property to use for comparison.
 * @returns {number} - Returns a negative, zero, or positive value
 *                    to indicate the sorting order.
 */
function descendingComparator(a, b, orderBy) {
  if (typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string') {
    return b[orderBy].localeCompare(a[orderBy], undefined, {
      numeric: true,
    })
  }

  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

/**
 * Returns a comparator function based on the specified order and property.
 *
 * @param {string} order - The sorting order ('asc' or 'desc').
 * @param {string} orderBy - The property to use for comparison.
 * @returns {Function} - A comparator function for sorting objects.
 */
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
