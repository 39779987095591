import { createAction } from 'redux-actions'
import {
  RECEIVE_CREATE_INTEREST_SIGNUP,
  RECEIVE_CREATE_SALES_START_SIGNUP,
  RECEIVE_UPDATE_SALES_START_SIGNUP,
} from '../user'
import { RECEIVE_CREATE_INTERESTS } from '../interest'
import { RECEIVE_SEND_SIGNUP_EMAIL } from '../email'
import { RECEIVE_CREATE_EVENT_SIGNUP } from '../eventSignup'
import { RECEIVE_CREATE_FELLOW } from '../fellow'

export const SHOW_ALERT = 'Show alert'
export const HIDE_ALERT = 'Hide alert'

export const showAlert = createAction(SHOW_ALERT)
export const hideAlert = createAction(HIDE_ALERT)

const reducer = (state = { message: undefined }, { type, payload, error }) => {
  switch (type) {
    case SHOW_ALERT:
      return { message: payload }
    case HIDE_ALERT:
      return { message: undefined }
    case RECEIVE_CREATE_INTEREST_SIGNUP:
    case RECEIVE_CREATE_SALES_START_SIGNUP:
    case RECEIVE_UPDATE_SALES_START_SIGNUP:
    case RECEIVE_CREATE_INTERESTS:
    case RECEIVE_SEND_SIGNUP_EMAIL:
    case RECEIVE_CREATE_EVENT_SIGNUP:
    case RECEIVE_CREATE_FELLOW:
      if (error) {
        return { message: payload.message }
      }
      return state
    default:
      return state
  }
}

export default reducer
