import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

const Plausible = ({ domain }) => {
  if (!domain) return false
  return (
    <Helmet>
      <script
        defer
        data-domain={domain}
        src="https://plausible.io/js/plausible.js"
      ></script>
    </Helmet>
  )
}

const mapStateToProps = ({ company }) => {
  return { domain: company?.plausibleDomain }
}

export default connect(mapStateToProps)(Plausible)
