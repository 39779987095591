import { connect } from 'react-redux'
import { CircularProgress, Stack } from '@mui/material'
import NotFound from '../notFound'
import SignupUnopened from '../signupUnopened'
import StartPage from '../startPage'
import InterestSignupForm from '../interestSignupForm'
import SalesStartSignupForm from '../salesStartSignupForm'
import InterestsPage from '../interestsPage'
import EndPage from '../endPage'
import SignupClosed from '../signupClosed'

export const UnopenedPage = (props) => <SignupUnopened {...props} />

export const UserPage = (props) => {
  if (props?.project?.interestSignup) {
    return <InterestSignupForm {...props} />
  }
  return <SalesStartSignupForm {...props} />
}

const ClosedPage = (props) => <SignupClosed {...props} />

export const Signup = (props) => {
  const page = props.page || {}
  const pages = page.pages || []
  const index = page.index || 0
  const currentPage = pages.length && pages[index]
  switch (currentPage) {
    case 'NOT_FOUND':
      return <NotFound {...props} />
    case 'UNOPENED':
      return <UnopenedPage {...props} />
    case 'START':
      return <StartPage {...props} />
    case 'USER':
      return <UserPage {...props} />
    case 'INTERESTS':
      return <InterestsPage {...props} />
    case 'END':
      return <EndPage {...props} />
    case 'CLOSED':
      return <ClosedPage {...props} />
  }
  return (
    <Stack alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>
  )
}

const mapStateToProps = (state) => {
  return {
    page: state?.page,
    project: state?.project,
  }
}

export default connect(mapStateToProps)(Signup)
