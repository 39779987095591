import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'

/**
The `debug` option toggles console logging of triggered events, see:
  https://github.com/zsajjad/react-facebook-pixel#how-to-use
The `autoConfig` option toggles triggering of event on all button clicks, see:
  https://developers.facebook.com/docs/facebook-pixel/advanced#automatic-configuration
**/
const options = { debug: false, autoConfig: false }

const FacebookPixel = ({ reactPixel }) => {
  const pixelId = useSelector((state) => state?.project?.facebookPixelId)
  const acceptTracking = useSelector((state) => state?.user?.acceptTracking)
  const trackInterestSignup = useSelector(
    (state) => state?.user?.signupType == 'INTEREST'
  )
  const trackSalesSignup = useSelector(
    (state) => state?.user?.signupType == 'SALES_START'
  )

  const acceptTrackingShowingSignup = useSelector(
    (state) => state?.showingSignup?.data?.acceptTracking
  )
  const trackShowingSignup = useSelector(
    (state) => state?.showingSignup?.data?.signupType == 'SHOWING'
  )

  useEffect(() => {
    // Tracking of interest signups
    if (!pixelId) return
    if (!acceptTracking) return
    if (!trackInterestSignup) return
    reactPixel?.init(pixelId, {}, options)
    reactPixel?.pageView()
    reactPixel?.track('Lead')
  }, [pixelId, reactPixel, acceptTracking, trackInterestSignup])

  useEffect(() => {
    // Tracking of sales start signups
    if (!pixelId) return
    if (!acceptTracking) return
    if (!trackSalesSignup) return
    reactPixel?.init(pixelId, {}, options)
    reactPixel?.pageView()
    reactPixel?.track('Lead')
  }, [pixelId, reactPixel, acceptTracking, trackSalesSignup])

  useEffect(() => {
    // Tracking of showing signups
    if (!pixelId) return
    if (!acceptTrackingShowingSignup) return
    if (!trackShowingSignup) return
    reactPixel?.init(pixelId, {}, options)
    reactPixel?.pageView()
    reactPixel?.track('Lead')
  }, [pixelId, reactPixel, acceptTrackingShowingSignup, trackShowingSignup])

  return false
}

const Wrapper = (props) => <FacebookPixel reactPixel={ReactPixel} {...props} />

export default Wrapper
