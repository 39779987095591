import { combineReducers } from 'redux'
import alert from './alert'
import auth from './auth'
import company from './company'
import eventSignup from './eventSignup'
import fellow from './fellow'
import htmls from './html'
import page from './page'
import project from './project'
import properties from './property'
import request from './request'
import showing from './showing'
import showingSignup from './showingSignup'
import user from './user'

export default combineReducers({
  alert,
  auth,
  company,
  eventSignup,
  fellow,
  htmls,
  page,
  project,
  properties,
  request,
  showing,
  showingSignup,
  user,
})
