import { useEffect } from 'react'
import { connect } from 'react-redux'
import { requestCompany } from '../../redux/company'
import { requestProject } from '../../redux/project'
import { requestHtmls } from '../../redux/html'
import { requestProperties } from '../../redux/property'

const DataFetcher = ({ dispatch, projectId }) => {
  useEffect(() => {
    dispatch(requestCompany())
    dispatch(requestProject(projectId))
    dispatch(requestHtmls(projectId))
    dispatch(requestProperties(projectId))
  }, [dispatch, projectId])

  return false
}

const mapStateToProps = (_, { match }) => {
  return {
    projectId: match?.params?.projectId,
  }
}

export default connect(mapStateToProps)(DataFetcher)
