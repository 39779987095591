import { createAction } from 'redux-actions'
import { request } from '../../api'
import { put, takeLatest, select } from 'redux-saga/effects'

export const REQUEST_HTML = 'Request html'
export const RECEIVE_HTML = 'Receive html'
export const REQUEST_HTMLS = 'Request htmls'
export const RECEIVE_HTMLS = 'Receive htmls'

export const requestHtml = createAction(REQUEST_HTML, (projectId, key) => ({
  projectId,
  key,
}))
export const receiveHtml = createAction(RECEIVE_HTML)
export const requestHtmls = createAction(REQUEST_HTMLS, (projectId) => ({
  projectId,
}))
export const receiveHtmls = createAction(RECEIVE_HTMLS)

function* sagaGetHtml({ payload }) {
  try {
    const state = yield select()
    if (state.html.items.find((h) => h.key === payload.key)) {
      return
    }

    const { body: data } = yield request({
      method: 'GET',
      url: `/api/admin/projects/${payload.projectId}/htmls?key=${payload.key}`,
    })
    yield put(receiveHtml(data[0] || {}))
  } catch (error) {
    console.error(error)
    yield put(receiveHtml(error))
  }
}

function* sagaGetHtmls({ payload }) {
  try {
    const data = yield request({
      method: 'GET',
      url: `/api/user/projects/${payload.projectId}/htmls`,
    })
    yield put(receiveHtmls(data))
  } catch (error) {
    console.error(error)
    yield put(receiveHtmls(error))
  }
}

export function* sagas() {
  yield takeLatest(requestHtml, sagaGetHtml)
  yield takeLatest(requestHtmls, sagaGetHtmls)
}

const initialState = []

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_HTMLS:
      if (action.error) {
        return state
      } else {
        return action.payload
      }
    default:
      return state
  }
}

export default reducer
