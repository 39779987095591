import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

const ArchivedProjectCheck = ({ project, children }) => {
  const { t } = useTranslation()

  if (project?.status === 'ARCHIVED') {
    return <h1>{t('Project is archived')}</h1>
  }
  return children
}

const mapStateToProps = ({ project }) => {
  return {
    project: project,
  }
}

export default connect(mapStateToProps)(ArchivedProjectCheck)
