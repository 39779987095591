import { t } from '../../i18n'
import { toArea } from '../../helper'
import { toPrice } from '../Price'

const DEFAULT = ['ROOMS', 'AREA', 'PRICE', 'NUMBER']

export const toStatus = (status) => {
  if (!status) return '-'
  if (status === 'AVAILABLE') return t('Available')
  if (status === 'RESERVED') return t('Reserved')
  if (status === 'BOOKED') return t('Booked')
  if (status === 'SOLD') return t('Sold')
  if (status === 'LEASED') return t('Leased')
  if (status === 'UPCOMING') return t('Upcoming')
}

export const label = (property = {}, visibleFields, currency) => {
  const pieces = []

  const fields = visibleFields || DEFAULT
  fields?.forEach?.((field) => {
    switch (field) {
      case 'BUILDING':
        pieces.push(property?.building || '-')
        break
      case 'FLOOR':
        pieces.push(
          property?.floor || property?.floor === 0 ? property.floor : '-'
        )
        break
      case 'MONTHLY_FEE':
        pieces.push(
          property?.monthlyFee === null || property?.monthlyFee === undefined
            ? '-'
            : toPrice(property?.monthlyFee, currency)
        )
        break
      case 'NUMBER':
        pieces.push(property?.number || '-')
        break
      case 'OUTDOOR_SPACE_AREA':
        pieces.push(toArea(property?.outdoorSpaceArea))
        break
      case 'PRICE':
        pieces.push(
          property?.price === null || property?.price === undefined
            ? '-'
            : toPrice(property?.price, currency)
        )
        break
      case 'ROOMS':
        pieces.push(`${property?.rooms || '-'} ${t('rooms')}`)
        break
      case 'AREA':
        pieces.push(toArea(property?.area))
        break
      case 'STATUS':
        pieces.push(toStatus(property?.status))
        break
      case 'SUPPLEMENTAL_AREA':
        pieces.push(toArea(property?.supplementalArea))
    }
  })

  return pieces.join(' | ')
}

export const options = (
  properties = [],
  selectedProperties = [],
  config = [],
  currency
) => {
  const items = []
  properties.forEach((property) => {
    const { id } = property
    const text = label(property, config, currency)
    items.push(
      <option
        value={id || ''}
        label={text}
        key={id}
        disabled={selectedProperties.includes(id)}
      >
        {text}
      </option>
    )
  })
  return items
}

export const heading = (visibleFields) => {
  const pieces = []
  const fields = visibleFields || DEFAULT
  fields?.forEach?.((field) => {
    switch (field) {
      case 'BUILDING':
        pieces.push(t('Building'))
        break
      case 'FLOOR':
        pieces.push(t('Floor'))
        break
      case 'MONTHLY_FEE':
        pieces.push(t('Monthly fee'))
        break
      case 'NUMBER':
        pieces.push(t('Number'))
        break
      case 'OUTDOOR_SPACE_AREA':
        pieces.push(t('Land area'))
        break
      case 'PRICE':
        pieces.push(t('Price'))
        break
      case 'ROOMS':
        pieces.push(t('Number of rooms'))
        break
      case 'AREA':
        pieces.push(t('Area'))
        break
      case 'STATUS':
        pieces.push(t('Status'))
        break
      case 'SUPPLEMENTAL_AREA':
        pieces.push(t('Supplemental area'))
        break
    }
  })

  return pieces.join(' | ')
}
