import { Helmet } from 'react-helmet'

const Font = ({ font }) => {
  const { name, url, format } = font || {}
  let css = ''
  if (format) {
    css = `@font-face { font-family: ${name}; src: url(${url}) format("${format}");`
  } else if (url) {
    css = `@import url('${url}');`
  }

  return (
    <Helmet>
      <style>{css}</style>
    </Helmet>
  )
}

export default Font
