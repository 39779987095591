import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { requestCreateEventSignup } from '../../redux/eventSignup'
import { requestHtmls } from '../../redux/html'
import SignupComponent from './signupComponent'

const mapStateToProps = (state) => {
  const { isFetching, hasSignedUp, signup } = state.eventSignup
  return {
    isFetching,
    hasSignedUp,
    signup,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId)
  return {
    onMount: () => {
      requestHtmls()
    },
    onSubmit: (signup) => {
      dispatch(requestCreateEventSignup(projectId, signup))
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignupComponent)
)
