import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

const PrivacyPolicyDialog = ({ open, onClose, content }) => {
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('Privacy policy')}</DialogTitle>
      <DialogContent dangerouslySetInnerHTML={{ __html: content }} />
      <DialogActions>
        <Button onClick={onClose}>{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PrivacyPolicyDialog
