import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { ArrowDownward, ArrowUpward, Download } from '@mui/icons-material'
import { formatArea, formatMoney, getComparator } from './utils'
import Chip from './chip'

const CardStack = ({ fields, onClick, properties }) => {
  const { t } = useTranslation()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('number')
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClickSort = (field) => {
    const isAsc = orderBy === field && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(field)
    setAnchorEl(null)
  }

  const handleClickSortButton = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickToggleSortDirection = () => {
    setOrder(order === 'asc' ? 'desc' : 'asc')
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const labels = {
    number: t('Sort by number'),
    status: t('Sort by status'),
    price: t('Sort by price'),
    monthlyFee: t('Sort by monthly fee'),
    building: t('Sort by building'),
    rooms: t('Sort by rooms'),
    area: t('Sort by area'),
    floor: t('Sort by floor'),
  }

  let buttonLabel = labels[orderBy]

  const sorted = properties?.sort?.(getComparator(order, orderBy))

  return (
    <Stack spacing={1} alignItems="end">
      <Stack direction="row" alignItems="center">
        <Button
          id="sort-button"
          aria-controls={open ? 'sort-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickSortButton}
        >
          {buttonLabel}
        </Button>
        <Menu
          id="sort-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'sort-button',
            sx: { width: anchorEl?.offsetWidth },
          }}
        >
          <MenuItem
            selected={orderBy === 'number'}
            onClick={() => handleClickSort('number')}
          >
            {t('Number')}
          </MenuItem>
          {fields?.includes?.('STATUS') && (
            <MenuItem
              selected={orderBy === 'status'}
              onClick={() => handleClickSort('status')}
            >
              {t('Status')}
            </MenuItem>
          )}
          {fields?.includes?.('PRICE') && (
            <MenuItem
              selected={orderBy === 'price'}
              onClick={() => handleClickSort('price')}
            >
              {t('Price')}
            </MenuItem>
          )}
          {fields?.includes?.('MONTHLY_FEE') && (
            <MenuItem
              selected={orderBy === 'monthlyFee'}
              onClick={() => handleClickSort('monthlyFee')}
            >
              {t('Monthly fee')}
            </MenuItem>
          )}
          {fields?.includes?.('BUILDING') && (
            <MenuItem
              selected={orderBy === 'building'}
              onClick={() => handleClickSort('building')}
            >
              {t('Building')}
            </MenuItem>
          )}
          {fields?.includes?.('ROOMS') && (
            <MenuItem
              selected={orderBy === 'rooms'}
              onClick={() => handleClickSort('rooms')}
            >
              {t('Rooms')}
            </MenuItem>
          )}
          {fields?.includes?.('AREA') && (
            <MenuItem
              selected={orderBy === 'area'}
              onClick={() => handleClickSort('area')}
            >
              {t('Area')}
            </MenuItem>
          )}
          {fields?.includes?.('FLOOR') && (
            <MenuItem
              selected={orderBy === 'floor'}
              onClick={() => handleClickSort('floor')}
            >
              {t('Floor')}
            </MenuItem>
          )}
        </Menu>
        <IconButton
          aria-label="toggle sort direction"
          size="small"
          color="primary"
          onClick={handleClickToggleSortDirection}
        >
          {order === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
        </IconButton>
      </Stack>
      <Stack spacing={1} sx={{ width: 1 }} divider={<Divider flexItem />}>
        {sorted?.map?.((p) => (
          <Box
            key={p.id}
            sx={{
              p: 2,
              ...(onClick && p.status !== 'UPCOMING'
                ? { cursor: 'pointer' }
                : {}),
            }}
            onClick={() => {
              if (onClick && p.status !== 'UPCOMING') onClick(p)
            }}
          >
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">{p.number}</Typography>
                {fields?.includes?.('STATUS') && <Chip status={p.status} />}
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                divider={<Divider orientation="vertical" flexItem />}
              >
                {fields?.includes?.('BUILDING') && (
                  <Typography>{p.building}</Typography>
                )}
                {fields?.includes?.('ROOMS') && (
                  <Typography>{t('{{n}} rooms', { n: p.rooms })}</Typography>
                )}
                {fields?.includes?.('AREA') && (
                  <Typography>{formatArea(p.area)}</Typography>
                )}
                {fields?.includes?.('FLOOR') && (
                  <Typography>{t('floor {{n}}', { n: p.floor })}</Typography>
                )}
              </Stack>
              {fields?.includes?.('FLOOR_PLAN_URL') && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>{t('Floor plan')}</Typography>
                  {p.floorPlanUrl && (
                    <IconButton
                      size="small"
                      aria-label="download"
                      href={p.floorPlanUrl}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      <Download />
                    </IconButton>
                  )}
                </Stack>
              )}
              {fields?.includes?.('PRICE') && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('Price')}</Typography>
                  <Typography>{formatMoney(p.price)}</Typography>
                </Stack>
              )}
              {fields?.includes?.('MONTHLY_FEE') && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography>{t('Monthly fee')}</Typography>
                  <Typography>{formatMoney(p.monthlyFee)}</Typography>
                </Stack>
              )}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}
export default CardStack
