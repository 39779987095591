import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'

const ChipComponent = (props) => {
  const { t } = useTranslation()

  if (!props?.status) return false

  let label
  if (props?.status == 'AVAILABLE') {
    label = t('Available')
  }
  if (props?.status == 'RESERVED') {
    label = t('Reserved')
  }
  if (props?.status == 'BOOKED') {
    label = t('Booked')
  }
  if (props?.status == 'SOLD') {
    label = t('Sold')
  }
  if (props?.status == 'LEASED') {
    label = t('Leased')
  }
  if (props?.status == 'UPCOMING') {
    label = t('Upcoming')
  }
  return (
    <Chip
      label={label}
      color={props?.status?.toLowerCase()}
      sx={{ fontWeight: 400 }}
      {...props}
    />
  )
}

export default ChipComponent
