import { useSelector, useDispatch } from 'react-redux'
import { Snackbar } from '@mui/material'
import { hideAlert } from '../../redux/alert'

const AlertComponent = () => {
  const message = useSelector((state) => state?.alert?.message)
  const dispatch = useDispatch()
  return (
    <Snackbar
      message={message || ''}
      open={!!message}
      onClose={() => dispatch(hideAlert())}
    />
  )
}

export default AlertComponent
