import { createAction } from 'redux-actions'
import { request } from '../../api'
import { put, takeLatest } from 'redux-saga/effects'

export const REQUEST_CREATE_INTERESTS = 'Request create interests'
export const RECEIVE_CREATE_INTERESTS = 'Receive create interests'

export const requestCreateInterests = createAction(
  REQUEST_CREATE_INTERESTS,
  (auth, interests) => ({ auth, interests })
)
export const receiveCreateInterests = createAction(RECEIVE_CREATE_INTERESTS)

function* sagaCreateInterests({ payload }) {
  try {
    const data = yield request({
      method: 'POST',
      url: `/api/user/interests`,
      body: payload.interests,
      headers: { Authorization: `Bearer ${payload.auth.jwtToken}` },
    })

    yield put(receiveCreateInterests(data))
  } catch (error) {
    console.error(error)
    yield put(receiveCreateInterests(error))
  }
}

export function* sagas() {
  yield takeLatest(requestCreateInterests, sagaCreateInterests)
}
