import { enGB, sv, fi } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'

export function getCurrentLocale() {
  const locales = { en: enGB, sv, fi }
  const language = localStorage.getItem('i18nextLng')
  return locales[language] || sv
}

const parseDate = (date, dateFormat) => {
  if (!date) return null

  if (date instanceof Date) {
    return format(date, dateFormat, { locale: getCurrentLocale() })
  }

  let parsed
  if (date[date.length - 1] === 'Z' || date.includes('+')) {
    parsed = date
  } else {
    parsed = `${date} UTC`
    if (isNaN(parsed)) {
      parsed = `${date}Z`
    }
  }
  return format(parseISO(parsed), dateFormat, { locale: getCurrentLocale() })
}

export const formatDateTime = (dateTime) => {
  return parseDate(dateTime, 'Pp') || ''
}

export function toArea(area) {
  if (!parseFloat(area)) {
    return '- m²'
  }
  return `${area} m²`
}
