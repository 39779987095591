import { Component } from 'react'
import Mailcheck from 'mailcheck'
import { TextField } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { isValidEmail } from '../validation'

const domains = [
  'gmail.com',
  'hotmail.com',
  'live.se',
  'outlook.com',
  'hotmail.se',
  'telia.com',
  'yahoo.com',
  'yahoo.se',
  'icloud.com',
  'live.com',
  'me.com',
  'comhem.se',
]

const topLevelDomains = [
  'com',
  'se',
  'net',
  'nu',
  'co.uk',
  'fr',
  'no',
  'pl',
  'fi',
  'it',
  'eu',
  'es',
  'ru',
  'de',
]

class EmailField extends Component {
  static getDerivedStateFromProps(props) {
    const { touched, email, t } = props

    if (touched) {
      const error = !isValidEmail(email)
      if (error) {
        return { error: t('Enter email address') }
      }
    }
    return null
  }

  state = {
    error: '',
  }

  getErrorText = (value) => {
    const check = Mailcheck.run({
      email: value,
      domains,
      topLevelDomains,
    })
    let error = ''
    if (!isValidEmail(value)) {
      error = this.props.t('Enter email address')
    } else if (check) {
      error = (
        <span style={{ color: 'red' }}>
          {this.props.t('Do you mean')}{' '}
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => this.setEmail(check.full)}
          >
            {check.full}
          </span>
          ?
        </span>
      )
    }

    return error
  }

  handleChange = (e) => {
    const error = this.getErrorText(e.target.value)

    this.props.onBlur(e)
    this.props.onChange(e)

    this.setState({
      error,
    })
  }

  setEmail = (email) => {
    this.handleChange({ target: { name: 'email', value: email } })
  }

  render() {
    const { email, onChange, touched, t } = this.props
    const { error } = this.state

    return (
      <TextField
        id="email"
        fullWidth
        variant="filled"
        onChange={onChange}
        onBlur={this.handleChange}
        label={t('Email address')}
        name="email"
        autoComplete="email"
        type="email"
        value={email || ''}
        error={touched && !isValidEmail(email)}
        helperText={error}
      />
    )
  }
}

export default withTranslation()(EmailField)
