import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material'
import Html from '../html'

const PrivacyPolicy = () => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  const handleToggle = () => {
    setShow(!show)
  }

  return (
    <>
      <Button onClick={handleToggle}>{t('Show')}</Button>
      <Dialog open={show} onClose={handleToggle}>
        <DialogContent>
          <Html name="privacy-policy" />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleToggle}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PrivacyPolicy
