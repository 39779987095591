import { createAction } from 'redux-actions'
import { request } from '../../api'
import { put, takeLatest } from 'redux-saga/effects'

export const REQUEST_PROPERTIES = 'Request properties'
export const RECEIVE_PROPERTIES = 'Receive properties'

export const requestProperties = createAction(
  REQUEST_PROPERTIES,
  (projectId) => ({ projectId })
)
export const receiveProperties = createAction(RECEIVE_PROPERTIES)

function* sagaGetProperties({ payload }) {
  try {
    const data = yield request({
      method: 'GET',
      url: `/api/user/projects/${payload.projectId}/properties`,
    })
    yield put(receiveProperties(data))
  } catch (error) {
    console.error(error)
    yield put(receiveProperties(error))
  }
}

export function* sagas() {
  yield takeLatest(requestProperties, sagaGetProperties)
}

const initialState = []

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PROPERTIES:
      return action.payload
    default:
      return state
  }
}

export default reducer
