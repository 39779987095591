import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { CssBaseline } from '@mui/material'
import DataFetcher from './components/dataFetcher'
import EventSignupPage from './components/eventSignupPage'
import ShowingSignupPage from './components/showingSignupPage'
import Layout from './components/layout'
import NotFound from './components/notFound'
import PixelSourceReceiver from './components/pixelSourceReceiver'
import Signup from './components/signup'
import SignupClosed from './components/signupClosed'
import SignupUnopened from './components/signupUnopened'
import ArchivedProjectCheck from './components/archivedProjectCheck'
import ListWidget from './components/listWidget'
import FormWidget from './components/formWidget'
import Theme from './theme'
import './i18n'

const App = ({ store }) => (
  <Provider store={store}>
    <Theme>
      <CssBaseline />
      <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <Route path="/projekt/:projectId" component={DataFetcher} />
        <Route path="/projects/:projectId" component={DataFetcher} />
        <Route component={PixelSourceReceiver} />
        <ArchivedProjectCheck>
          <Switch>
            <Route exact path="/projekt/:projectId/anmalan-stangd">
              <Layout>
                <SignupClosed />
              </Layout>
            </Route>
            <Route exact path="/projects/:projectId/closed">
              <Layout>
                <SignupClosed />
              </Layout>
            </Route>
            <Route exact path="/projekt/:projectId/anmalan-ej-oppnad">
              <Layout>
                <SignupUnopened />
              </Layout>
            </Route>
            <Route exact path="/projects/:projectId/unopened">
              <Layout>
                <SignupUnopened />
              </Layout>
            </Route>
            <Route exact path="/projekt/:projectId/anmalan-event">
              <Layout>
                <EventSignupPage />
              </Layout>
            </Route>
            <Route exact path="/projects/:projectId/events">
              <Layout>
                <EventSignupPage />
              </Layout>
            </Route>
            <Route exact path="/projects/:projectId/showings">
              <Layout>
                <ShowingSignupPage />
              </Layout>
            </Route>
            <Route exact path="/projects/:projectId/list-widget">
              <ListWidget />
            </Route>
            <Route exact path="/projects/:projectId/form-widget">
              <FormWidget />
            </Route>
            <Route exact path="/projekt/:projectId">
              <Layout>
                <Signup />
              </Layout>
            </Route>
            <Route exact path="/projects/:projectId">
              <Layout>
                <Signup />
              </Layout>
            </Route>
            <Route>
              <Layout>
                <NotFound />
              </Layout>
            </Route>
          </Switch>
        </ArchivedProjectCheck>
      </BrowserRouter>
    </Theme>
  </Provider>
)

export default App
