import {
  RECEIVE_CREATE_INTEREST_SIGNUP,
  RECEIVE_CREATE_SALES_START_SIGNUP,
} from '../user'

const initialState = { jwtToken: undefined }

const reducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case RECEIVE_CREATE_INTEREST_SIGNUP:
    case RECEIVE_CREATE_SALES_START_SIGNUP:
      if (error) {
        return state
      }
      return { jwtToken: payload.jwtToken }
    default:
      return state
  }
}

export default reducer
