import { createAction } from 'redux-actions'
import { request } from '../../api'
import { takeLatest, put } from 'redux-saga/effects'

export const REQUEST_PROJECT = 'Request project'
export const RECEIVE_PROJECT = 'Receive project'

export const requestProject = createAction(REQUEST_PROJECT, (projectId) => ({
  projectId,
}))
export const receiveProject = createAction(RECEIVE_PROJECT)

function* sagaGetProject({ payload }) {
  try {
    const data = yield request({
      method: 'GET',
      url: `/api/user/projects/${payload.projectId}`,
    })
    yield put(receiveProject(data))
  } catch (error) {
    console.error(error)
    yield put(receiveProject(error))
  }
}

export function* sagas() {
  yield takeLatest(requestProject, sagaGetProject)
}

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PROJECT:
      return action.payload
    default:
      return state
  }
}

export default reducer
