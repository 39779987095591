import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { requestProject } from '../../redux/project'

const DocVisible = ({ onVisible }) => {
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState == 'visible') {
        onVisible()
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [onVisible])

  return false
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = Number(ownProps.match.params.projectId)
  return {
    onVisible: () => {
      dispatch(requestProject(projectId))
    },
  }
}

export default withRouter(connect(undefined, mapDispatchToProps)(DocVisible))
