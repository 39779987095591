import {
  RECEIVE_CREATE_SALES_START_SIGNUP,
  RECEIVE_UPDATE_SALES_START_SIGNUP,
  RECEIVE_CREATE_INTEREST_SIGNUP,
} from '../user'
import { RECEIVE_PROJECT } from '../project'
import { RECEIVE_CREATE_INTERESTS } from '../interest'

const initialState = {
  pages: [],
  index: 0,
}

const reducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case RECEIVE_PROJECT:
      if (!payload.formStatus) {
        return {
          ...state,
          pages: ['NOT_FOUND'],
        }
      }
      if (payload.formStatus === 'UNOPENED') {
        if (payload.useCountdown) {
          if (payload.bookingEnabled) {
            return {
              pages: ['START', 'USER', 'INTERESTS', 'END'],
              index: 0,
            }
          }
          return {
            pages: ['START', 'USER', 'END'],
            index: 0,
          }
        }
        return {
          ...state,
          pages: ['UNOPENED'],
        }
      }
      if (payload.formStatus === 'CLOSED') {
        return {
          ...state,
          pages: ['CLOSED'],
        }
      }
      if (payload.interestSignup) {
        if (payload.bookingEnabled) {
          return {
            ...state,
            pages: ['USER', 'INTERESTS', 'END'],
          }
        }
        return {
          ...state,
          pages: ['USER', 'END'],
        }
      }
      if (payload.bookingEnabled) {
        return {
          ...state,
          pages: ['START', 'USER', 'INTERESTS', 'END'],
        }
      }
      return {
        ...state,
        pages: ['START', 'USER', 'END'],
      }
    case RECEIVE_CREATE_INTEREST_SIGNUP:
    case RECEIVE_CREATE_SALES_START_SIGNUP:
    case RECEIVE_UPDATE_SALES_START_SIGNUP:
    case RECEIVE_CREATE_INTERESTS:
      if (error) return state
      return {
        ...state,
        index: ++state.index,
      }
    default:
      return state
  }
}

export default reducer
