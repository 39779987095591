import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Stack,
} from '@mui/material'
import Html from '../html'
import { heading, options } from './options'

const InterestsPageComponent = ({
  auth,
  project = {},
  properties,
  interests,
  loading,
  onSubmit,
  currency,
}) => {
  const { t } = useTranslation()
  const [state, setState] = useState(
    interests || {
      properties: [],
      parking: false,
      addonOption: false,
    }
  )
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleSubmit = (ev) => {
    ev.preventDefault()
    if (loading) {
      return
    }
    setSubmitted(true)
    if (!project.mandatoryInterests) {
      onSubmit(auth, state)
    } else {
      if (state.properties.length) {
        onSubmit(auth, state)
      }
    }
  }

  const isValid = !!state.properties.length

  const head = heading(project.propertyVisibleFields)

  const minimumInterests = project.minimumInterests || 3

  const f = state.properties.filter((v) => !!v && v !== 0)
  let fields = f
  if (fields.length < minimumInterests) {
    fields = fields.concat(
      Array.from(new Array(minimumInterests - fields.length)).map(() => '')
    )
  }

  if (project.noLimitInterests && f.length >= minimumInterests) {
    fields = [...fields, '']
  }

  const defaultLabels = [
    t('First choice'),
    t('Second choice'),
    t('Third choice'),
  ]

  const getLabel = (i) => {
    const choice = i + 1
    if (minimumInterests === 3 && !project.noLimitInterests) {
      return defaultLabels[i]
    } else {
      if (choice === 1) {
        return `${choice}${t('st')} ${t('choice')}`
      } else if (choice === 2) {
        return `${i + 1}${t('nd')} ${t('choice')}`
      } else if (choice === 3) {
        return `${choice}${t('rd')} ${t('choice')}`
      } else {
        return `${choice}${t('th')} ${t('choice')}`
      }
    }
  }

  return (
    <Stack component="form" spacing={3} onSubmit={handleSubmit}>
      <Html name="choose-properties" />
      <Stack spacing={1} sx={{ maxWidth: 'sm' }}>
        {fields.map((id, ind) => (
          <FormControl key={ind} variant="filled" error={submitted && !isValid}>
            <InputLabel shrink htmlFor={`propertyId${ind + 1}`}>
              {getLabel(ind)}
            </InputLabel>
            <Select
              native
              inputProps={{
                id: `propertyId${ind + 1}`,
                name: `propertyId${ind + 1}`,
              }}
              value={id}
              onChange={(e) => {
                const val = parseInt(e.target.value)
                setState({
                  ...state,
                  properties: fields
                    .map((v, i) => (i === ind ? val : v))
                    .filter((v) => !!v && v !== 0),
                })
              }}
            >
              <option value="">{head}</option>
              {options(
                properties,
                state.properties,
                project.propertyVisibleFields,
                currency
              )}
            </Select>
          </FormControl>
        ))}
        {project.parking && (
          <FormControlLabel
            label={<Html name="parking" />}
            control={
              <Checkbox
                name="parking"
                checked={state.parking}
                onChange={() =>
                  setState({
                    ...state,
                    ...{ parking: !state.parking },
                  })
                }
              />
            }
          />
        )}
        {project.addonOption && (
          <FormControlLabel
            label={<Html name="addon-option" />}
            control={
              <Checkbox
                name="addonOption"
                checked={state.addonOption}
                onChange={() =>
                  setState({
                    ...state,
                    ...{ addonOption: !state.addonOption },
                  })
                }
              />
            }
          />
        )}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Button variant="contained" type="submit" onSubmit={handleSubmit}>
          {t('Continue')}
        </Button>
        {loading && <CircularProgress size={20} />}
      </Stack>
    </Stack>
  )
}

const mapStateToProps = ({ company }) => {
  return {
    currency: company?.currency,
  }
}

export default connect(mapStateToProps)(InterestsPageComponent)
