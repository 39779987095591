import { Helmet } from 'react-helmet'
import { Box, Paper, Stack } from '@mui/material'
import Alert from '../alert'

const LOGO_DEFAULT_WIDTH = 800

export const ProjectLogo = ({ project }) => {
  if (!project?.logo?.url) return null
  const logoWidth = project?.logo?.width || LOGO_DEFAULT_WIDTH
  return (
    <Stack alignItems="center">
      <Box
        component="img"
        src={project?.logo?.url}
        sx={{ m: logoWidth < 800 ? 5 : 0, width: '100%', maxWidth: logoWidth }}
        alt={project?.name}
      />
    </Stack>
  )
}

export const CompanyLogo = ({ project, company }) => {
  if (project?.companyLogo?.url) {
    return (
      <Stack alignItems="center">
        <Box
          component="img"
          src={project?.companyLogo?.url}
          sx={{
            m: 5,
            width: '100%',
            maxWidth: project?.companyLogo?.width || LOGO_DEFAULT_WIDTH,
          }}
          alt={project?.name}
        />
      </Stack>
    )
  }
  if (company?.logo?.url) {
    return (
      <Stack alignItems="center">
        <Box
          component="img"
          src={company?.logo?.url}
          sx={{
            m: 5,
            width: '100%',
            maxWidth: company?.logo?.width || LOGO_DEFAULT_WIDTH,
          }}
          alt={company?.name}
        />
      </Stack>
    )
  }
  return false
}

const LayoutComponent = ({ children, project, company }) => (
  <>
    <Helmet>
      <title>{project?.name || 'Rebla CRM'}</title>
    </Helmet>
    <Alert />
    <Stack alignItems="center">
      <Stack alignItems="center">
        <Paper
          sx={{
            width: '100vw',
            maxWidth: 800,
          }}
        >
          <ProjectLogo project={project} />
          <Box sx={{ p: 3 }}>{children}</Box>
        </Paper>
        <CompanyLogo project={project} company={company} />
      </Stack>
    </Stack>
  </>
)

export default LayoutComponent
