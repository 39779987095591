import { createAction } from 'redux-actions'
import { request } from '../../api'
import { put, takeLatest } from 'redux-saga/effects'

export const RECEIVE_CREATE_FELLOW = 'Receive create fellow'
export const REQUEST_CREATE_FELLOW = 'Request create fellow'
export const RECEIVE_DELETE_FELLOW = 'Receive delete fellow'
export const REQUEST_DELETE_FELLOW = 'Request delete fellow'
export const OPEN_FELLOW_DIALOG = 'Open fellow dialog'
export const CLOSE_FELLOW_DIALOG = 'Close fellow dialog'

export const receiveCreateFellow = createAction(RECEIVE_CREATE_FELLOW)
export const requestCreateFellow = createAction(
  REQUEST_CREATE_FELLOW,
  (auth, fellow) => ({ auth, fellow })
)
export const receiveDeleteFellow = createAction(RECEIVE_DELETE_FELLOW)
export const requestDeleteFellow = createAction(
  REQUEST_DELETE_FELLOW,
  (auth, fellow) => ({ auth, fellow })
)

export const openFellowDialog = createAction(OPEN_FELLOW_DIALOG)
export const closeFellowDialog = createAction(CLOSE_FELLOW_DIALOG)

function* sagaCreateFellow({ payload }) {
  try {
    const data = yield request({
      method: 'POST',
      url: `/api/user/fellows`,
      body: payload.fellow,
      headers: { Authorization: `Bearer ${payload.auth.jwtToken}` },
    })
    yield put(receiveCreateFellow(data))
  } catch (error) {
    console.error(error)
    yield put(receiveCreateFellow(error))
  }
}

function* sagaDeleteFellow({ payload }) {
  try {
    const data = yield request({
      method: 'DELETE',
      url: `/api/user/fellows/${payload.fellow.id}`,
      headers: { Authorization: `Bearer ${payload.auth.jwtToken}` },
    })
    yield put(receiveDeleteFellow(data))
  } catch (error) {
    console.error(error)
    yield put(receiveDeleteFellow(error))
  }
}

export function* sagas() {
  yield takeLatest(requestCreateFellow, sagaCreateFellow)
  yield takeLatest(requestDeleteFellow, sagaDeleteFellow)
}

const initialState = {
  items: [],
  openDialog: false,
  isFetching: false,
}

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action
  switch (type) {
    case REQUEST_CREATE_FELLOW:
      return {
        ...state,
        ...{ isFetching: true },
      }
    case RECEIVE_CREATE_FELLOW:
      if (error) {
        return { ...state, ...{ isFetching: false } }
      }
      return {
        ...state,
        ...{
          items: [...state.items, payload],
        },
        ...{ openDialog: false },
        ...{ isFetching: false },
      }
    case REQUEST_DELETE_FELLOW:
      return {
        ...state,
        ...{ isFetching: true },
      }
    case RECEIVE_DELETE_FELLOW:
      if (error) {
        return { ...state, ...{ isFetching: false } }
      }
      return {
        ...state,
        ...{ items: state.items.filter((f) => f.id != payload.id) },
        ...{ isFetching: false },
      }
    case CLOSE_FELLOW_DIALOG:
      return {
        ...state,
        ...{ openDialog: false },
      }
    case OPEN_FELLOW_DIALOG:
      return {
        ...state,
        ...{ openDialog: true },
      }
    default:
      return state
  }
}

export default reducer
