import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { receiveUserSource } from '../../redux/user'

const PixelSourceReceiver = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const removeListener = install(dispatch)
    return removeListener
  })

  return <div />
}

export default PixelSourceReceiver

const install = (dispatch) => {
  const originWindow = originatingWindow()
  if (!originWindow) return
  const removeListener = listenForMessageEvents(dispatch)
  postPingMessage(originWindow)
  return removeListener
}

// If loaded in frame or opened in popup, this returns the window on the other
// end, allowing messages to be posted.
export const originatingWindow = () => {
  if (window.opener) return window.opener
  if (window.parent !== window.self) return window.parent
  return null
}

export const postPingMessage = (originatingWindow) => {
  originatingWindow.postMessage({ reblaPixel: 'ping' }, '*')
}

export const listenForMessageEvents = (
  dispatch,
  receiveEvent = receiveMessageEvent
) => {
  const handler = (event) => {
    receiveEvent(event, dispatch)
  }
  window.addEventListener('message', handler)
  return () => {
    window.removeEventListener('message', handler)
  }
}

export const extractSourceFromEvent = (event) => {
  if (!event.data) return null
  if (!event.data.reblaPixel) return null
  if (!event.data.reblaPixel.source) return null
  return event.data.reblaPixel.source
}

export const receiveMessageEvent = (
  event,
  dispatch,
  extractSource = extractSourceFromEvent,
  saveSource = saveSourceInState
) => {
  const source = extractSource(event)
  if (source) {
    saveSource(source, dispatch)
  }
}

export const saveSourceInState = (source, dispatch) => {
  dispatch(receiveUserSource(source))
}
