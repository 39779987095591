export const isValidPersonalNumber = (value) => {
  return /^\d{12}$/.test(value)
}

export const isValidOrganizationNumber = (value) => {
  return /^\d{6}-\d{4}$/.test(value)
}

export const isValidOrganizationName = (value) => {
  return /\S+/.test(value)
}

export const isValidFirstName = (value) => {
  return /\S+/.test(value)
}

export const isValidLastName = (value) => {
  return /\S+/.test(value)
}

export const isValidStreet = (value) => {
  return /\S+/.test(value)
}

export const isValidCity = (value) => {
  return /\S+/.test(value)
}

export const isValidZip = (value) => {
  return /^\d{3}\s{0,1}\d{2}$/.test(value)
}

export const isValidPhone = (value) => {
  if (!value) return false
  const regex = /^\+?[\d\s-()]{5,20}$/
  return regex.test(value.trim())
}

export const isValidEmail = (value) => {
  return /^[^@\s]+@[^@\s.]+\.[^@\s]+$/.test(value)
}

export const isValidBirthYear = (value) => {
  if (!value) {
    return true
  }

  if (/^\d{4}$/.test(value)) {
    return true
  }

  return false
}
