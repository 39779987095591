import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { requestCreateInterests } from '../../redux/interest'
import InterestsPageComponent from './interestsPageComponent'

const mapStateToProps = ({ project, properties, request, auth }) => {
  return {
    loading: request.createInterests || request.getProperties,
    project,
    properties,
    auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (auth, interests) => {
      dispatch(requestCreateInterests(auth, interests))
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InterestsPageComponent)
)
