import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Snackbar, Stack } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import SignupDialog from './signupDialog'
import ConfirmationDialog from './confirmationDialog'
import Table from './table'
import CardStack from './cardStack'
import { getToken } from './utils'

const ListWidget = () => {
  const { t } = useTranslation()
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [openSignup, setOpenSignup] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const properties = useSelector((state) => state?.properties)
  const project = useSelector((state) => state?.project)

  const isWidescreen = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const handleClickProperty = (property) => {
    setSelectedProperty(property)
    setOpenSignup(true)
  }

  const handleCloseSignup = () => {
    setSelectedProperty(null)
    setOpenSignup(false)
  }

  const handleSuccessSignup = () => {
    setOpenConfirmation(true)
    setOpenSignup(false)
    setSelectedProperty(null)
  }

  const handleErrorSignup = () => {
    setOpenSnackbar(true)
  }

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  // TODO: handle this with a flag on the project
  const allowSignup = project.name !== 'Snötäcket 6 & 7'

  return (
    <Stack sx={{ backgroundColor: '#fff' }}>
      {isWidescreen ? (
        <Table
          fields={project?.propertyVisibleFields}
          onClick={allowSignup && handleClickProperty}
          properties={properties}
        />
      ) : (
        <CardStack
          fields={project?.propertyVisibleFields}
          onClick={allowSignup && handleClickProperty}
          properties={properties}
        />
      )}
      <SignupDialog
        fields={project?.propertyVisibleFields}
        isOrg={project?.allowedBuyers?.[0] === 'COMPANY'}
        onClose={handleCloseSignup}
        onSuccess={handleSuccessSignup}
        onError={handleErrorSignup}
        open={openSignup}
        property={selectedProperty}
        token={getToken(document)}
      />
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
      />
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={t('Something went wrong')}
      />
    </Stack>
  )
}

export default ListWidget
