import { useTranslation } from 'react-i18next'
const NotFound = () => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Open Sans, sans-serif',
      }}
    >
      <h1>{t('Page not found')}</h1>
    </div>
  )
}
export default NotFound
