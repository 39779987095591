import { createAction } from 'redux-actions'
import { request } from '../../api'
import { takeLatest, put } from 'redux-saga/effects'

export const REQUEST_COMPANY = 'Request company'
export const RECEIVE_COMPANY = 'Receive company'

export const requestCompany = createAction(REQUEST_COMPANY, (id) => ({ id }))
export const receiveCompany = createAction(RECEIVE_COMPANY)

function* sagaGetCompany({ payload }) {
  try {
    const data = yield request({
      method: 'GET',
      url: `/api/user/companies/${payload.id || 1}`,
    })
    yield put(receiveCompany(data))
  } catch (error) {
    yield put(receiveCompany(error))
  }
}

export const sagas = function* () {
  yield takeLatest(requestCompany, sagaGetCompany)
}

const initialState = []

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RECEIVE_COMPANY:
      return payload
    default:
      return state
  }
}

export default reducer
