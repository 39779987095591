export const getWindowLocationHref = () => {
  return window.location.href
}

export const getDocumentReferrer = () => {
  return document.referrer
}

import {
  isValidFirstName,
  isValidLastName,
  isValidPersonalNumber,
  isValidOrganizationNumber,
  isValidOrganizationName,
  isValidEmail,
  isValidPhone,
  isValidStreet,
  isValidZip,
  isValidCity,
} from '../../validation'

export const isValid = (prop) => {
  const [name, value] = Object.entries(prop)[0]
  switch (name) {
    case 'firstName':
      return isValidFirstName(value)
    case 'lastName':
      return isValidLastName(value)
    case 'personalNumber':
      return isValidPersonalNumber(value)
    case 'organizationNumber':
      return isValidOrganizationNumber(value)
    case 'organizationName':
      return isValidOrganizationName(value)
    case 'email':
      return isValidEmail(value)
    case 'phone':
      return isValidPhone(value)
    case 'street':
      return isValidStreet(value)
    case 'zip':
      return isValidZip(value)
    case 'city':
      return isValidCity(value)
    case 'acceptPrivacyPolicy':
      return !!value
    default:
      return true
  }
}

export const isValidUser = ({
  firstName,
  lastName,
  personalNumber,
  email,
  phone,
  street,
  zip,
  city,
}) => {
  return (
    isValid({ firstName }) &&
    isValid({ lastName }) &&
    isValid({ personalNumber }) &&
    isValid({ email }) &&
    isValid({ phone }) &&
    isValid({ street }) &&
    isValid({ zip }) &&
    isValid({ city })
  )
}

export const isValidCompany = ({
  firstName,
  lastName,
  organizationNumber,
  organizationName,
  email,
  phone,
  street,
  zip,
  city,
}) => {
  return (
    isValid({ firstName }) &&
    isValid({ lastName }) &&
    isValid({ organizationNumber }) &&
    isValid({ organizationName }) &&
    isValid({ email }) &&
    isValid({ phone }) &&
    isValid({ street }) &&
    isValid({ zip }) &&
    isValid({ city })
  )
}
