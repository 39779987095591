import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import { createRoot } from 'react-dom/client'
import { createStore, applyMiddleware, compose } from 'redux'
import * as Sentry from '@sentry/browser'
import { CaptureConsole } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
import rootReducer from './redux/reducers'
import App from './app'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './redux/sagas'

const { NODE_ENV, SOURCE_VERSION } = process.env

Sentry.init({
  release: `rebla-zo@${SOURCE_VERSION}`,
  dsn: 'https://122d6fbf70bd443ca77f9169c1e4a772@sentry.io/2300119',
  integrations: [
    new CaptureConsole({ levels: ['error'] }),
    new Integrations.BrowserTracing(),
  ],
  environment: NODE_ENV || 'development',
  tracesSampleRate: 0.2,
})

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App store={store} />)
