import { useState, useEffect } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, CircularProgress, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { requestCreateSalesStartSignup } from '../../redux/user'
import Html from '../html'
import DocVisible from './docVisible'
import Countdown from './countdown'

const StartPageComponent = ({
  pid,
  loading,
  onClickSignup,
  millisecondsLeft,
}) => {
  const [opensIn, setOpensIn] = useState(millisecondsLeft)
  const [projectId] = useState(pid)
  const { t } = useTranslation()

  useEffect(() => {
    const THROTTLE_AMOUNT = 450
    const interval = setInterval(() => {
      if (opensIn <= 0) {
        clearInterval(interval)
      } else {
        setOpensIn(opensIn - THROTTLE_AMOUNT)
      }
    }, THROTTLE_AMOUNT)
    return () => clearInterval(interval)
  }, [opensIn])

  useEffect(() => {
    const interval = setInterval(() => {
      if (opensIn <= 0) {
        clearInterval(interval)
      } else {
        console.log('.')
        fetch(`/api/user/projects/${projectId}/time`)
          .then((res) => res.json())
          .then((data) => {
            setOpensIn(data.opensIn)
          })
      }
    }, 15000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Route path="/projekt/:projectId" component={DocVisible} />
      <Stack spacing={3}>
        <Html name="signup-start-heading" />
        <Stack direction="row" justifyContent="center">
          {opensIn > 0 ? (
            <Countdown milliseconds={opensIn} />
          ) : (
            <Button
              variant="contained"
              onClick={() => !loading && onClickSignup()}
              style={{ width: 110 }}
            >
              {loading ? (
                <CircularProgress size={24} disableShrink color="inherit" />
              ) : (
                <span>{t('Continue')}</span>
              )}
            </Button>
          )}
        </Stack>
        <Html name="signup-start-body" />
      </Stack>
    </>
  )
}

const mapStateToProps = ({ request, project }) => {
  return {
    loading: request?.createSalesStartSignup,
    millisecondsLeft: project?.signupOpenInMillis,
    pid: project?.id,
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  const projectId = Number(match?.params?.projectId)

  return {
    onClickSignup: () => dispatch(requestCreateSalesStartSignup(projectId)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StartPageComponent)
)
