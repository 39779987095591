import { connect } from 'react-redux'
import Mustache from 'mustache'

export function getHtml(key = '', htmls = []) {
  const html = htmls.find((h) => h.key === key)
  if (!html) {
    return `Missing key: '${key}'`
  }

  if (!html.value) {
    return `Missing value for key: '${key}'`
  }

  return html.value
}

export function mustache(template, data) {
  return Mustache.render(template, data)
}

export const Html = ({ name, isLoading, htmls, data }) => {
  if (isLoading) return null

  const template = getHtml(name, htmls)

  return (
    <span>
      <div
        id={name}
        dangerouslySetInnerHTML={{
          __html: mustache(template, data),
        }}
      />
    </span>
  )
}

const mapStateToProps = ({ htmls, request }) => {
  return {
    htmls,
    isLoading: request?.getHtmls,
  }
}

export default connect(mapStateToProps)(Html)
