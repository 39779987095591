import { createAction } from 'redux-actions'
import { put, takeLatest } from 'redux-saga/effects'

export const REQUEST_CREATE_SHOWING_SIGNUP = 'Request create showing signup'
export const RECEIVE_CREATE_SHOWING_SIGNUP = 'Receive create showing signup'
export const RECEIVE_CREATE_SHOWING_SIGNUP_ERROR =
  'Receive create showing signup error'
export const requestCreateShowingSignup = createAction(
  REQUEST_CREATE_SHOWING_SIGNUP
)
export const receiveCreateShowingSignup = createAction(
  RECEIVE_CREATE_SHOWING_SIGNUP
)
export const receiveCreateShowingSignupError = createAction(
  RECEIVE_CREATE_SHOWING_SIGNUP_ERROR
)

function* sagaCreateShowingSignup({ payload }) {
  try {
    const response = yield fetch(
      `/api/user/projects/${payload.projectId}/showings/${payload.showingId}/signups`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    )
    const data = yield response.json()
    if (response.ok) {
      yield put(receiveCreateShowingSignup(data))
    } else {
      yield put(receiveCreateShowingSignupError(data))
    }
  } catch (error) {
    console.error(error)
    yield put(receiveCreateShowingSignupError(error))
  }
}

export function* sagas() {
  yield takeLatest(requestCreateShowingSignup, sagaCreateShowingSignup)
}

const initialState = { status: 'idle', data: null, error: null }

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_CREATE_SHOWING_SIGNUP:
      return {
        ...state,
        status: 'loading',
      }
    case RECEIVE_CREATE_SHOWING_SIGNUP:
      return { status: 'succeeded', data: payload }
    case RECEIVE_CREATE_SHOWING_SIGNUP_ERROR:
      return { status: 'failed', error: payload }
    default:
      return state
  }
}

export default reducer
