import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { Refresh } from '@mui/icons-material'
import Html from '../html'
import { getCurrentLocale } from '../../helper'
import { requestShowings } from '../../redux/showing'
import { requestCreateShowingSignup } from '../../redux/showingSignup'
import PrivacyPolicy from '../privacyPolicy'
import { grey } from '@mui/material/colors'

const formatRange = (locale, start, end) => {
  return new Intl.DateTimeFormat(locale, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).formatRange(new Date(start), new Date(end))
}

const ShowingSignupForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { projectId } = useParams()

  const showings = useSelector((state) => state?.showing?.items)
  const isLoading = useSelector((state) => state?.showing?.status === 'loading')

  const facebookPixelId = useSelector(
    (state) => state?.projects?.facebookPixelId
  )

  const signupError = useSelector((state) => state?.showingSignup?.error)
  const signupSuccess = useSelector(
    (state) => state?.showingSignup?.status === 'succeeded'
  )

  useEffect(() => {
    dispatch(requestShowings(projectId))
  }, [dispatch, projectId])

  const [state, setState] = useState({
    acceptPrivacyPolicy: false,
    acceptTracking: false,
    fullName: '',
    email: '',
    phone: '',
    showingId: 0,
  })

  function handleChange(event) {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  function handleCheck(event) {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  function handleClickReload() {
    dispatch(requestShowings(projectId))
  }

  function handleSubmit(e) {
    e.preventDefault()
    const data = {
      acceptPrivacyPolicy: state?.acceptPrivacyPolicy,
      acceptTracking: state?.acceptTracking,
      fullName: state?.fullName,
      email: state?.email,
      phone: state?.phone,
      showingId: state?.showingId,
      projectId,
    }
    dispatch(requestCreateShowingSignup(data))
  }

  if (signupSuccess) {
    const data = {
      ...state,
      showing: showings.find((s) => s.id == state.showingId),
    }
    // Add localized date range - prettyTime - to showing model
    data.showing.prettyTime = formatRange(
      getCurrentLocale()?.code,
      data.showing.startAt,
      data.showing.endAt
    )
    return <Html name="showing-signup-confirmation" data={data} />
  }

  return (
    <Grid component="form" onSubmit={handleSubmit} container spacing={1}>
      <Grid item xs={12}>
        <Html name="showing-signup-intro" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="filled"
          label={t('Name')}
          name="fullName"
          autoComplete="name"
          value={state.fullName}
          onChange={handleChange}
          error={!!signupError?.fullName}
          helperText={signupError?.fullName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="filled"
          label={t('Email address')}
          name="email"
          type="email"
          value={state.email}
          onChange={handleChange}
          error={!!signupError?.email}
          helperText={signupError?.email}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="filled"
          label={t('Phone number')}
          name="phone"
          type="tel"
          value={state.phone}
          onChange={handleChange}
          error={!!signupError?.phone}
          helperText={signupError?.phone}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2, backgroundColor: grey[100] }}
        >
          <Stack>
            {facebookPixelId && (
              <FormControlLabel
                label={t('I accept tracking for marketing purposes (optional)')}
                control={
                  <Checkbox
                    name="acceptTracking"
                    checked={state?.acceptTracking ? true : false}
                    onChange={handleCheck}
                  />
                }
              />
            )}
            <FormControl required error={!!signupError?.acceptPrivacyPolicy}>
              <FormControlLabel
                label={t(
                  'I have read the information about how my personal data is processed'
                )}
                control={
                  <Checkbox
                    name="acceptPrivacyPolicy"
                    checked={state?.acceptPrivacyPolicy ? true : false}
                    onChange={handleCheck}
                  />
                }
              />
              <FormHelperText>
                {signupError?.acceptPrivacyPolicy}
              </FormHelperText>
            </FormControl>
          </Stack>
          <PrivacyPolicy />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <h2>{t('Upcoming showings')}</h2>
          <Tooltip title={t('Reload the list')}>
            <IconButton onClick={handleClickReload}>
              <Refresh />
            </IconButton>
          </Tooltip>
          {isLoading && <CircularProgress color="inherit" size={24} />}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {!isLoading && showings?.length === 0 && <i>{t('No showings')}</i>}
        {!isLoading && showings?.length > 0 && (
          <RadioGroup
            name="showingId"
            value={state.showingId}
            onChange={handleChange}
          >
            {showings?.map?.((showing) => (
              <FormControlLabel
                key={showing.id}
                control={<Radio />}
                value={showing.id}
                label={
                  <Stack
                    sx={{ flexWrap: 'wrap', gap: 1 }}
                    direction="row"
                    alignItems="center"
                  >
                    <Typography>{showing.description}</Typography>
                    <Chip
                      label={formatRange(
                        getCurrentLocale()?.code,
                        showing.startAt,
                        showing.endAt
                      )}
                    />
                  </Stack>
                }
                disabled={showing.isFull}
              />
            ))}
          </RadioGroup>
        )}
      </Grid>
      <Grid item xs={12}>
        {signupError?.message && (
          <Alert severity="error">{signupError?.message}</Alert>
        )}
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" type="submit" onSubmit={handleSubmit}>
          {t('Send')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default ShowingSignupForm
