import { Component } from 'react'
import { connect } from 'react-redux'
import { requestSendSignupEmail } from '../../redux/email'
import Html from '../html'

class EndPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    const { onMount, auth } = this.props
    onMount(auth)
  }

  render() {
    const { user } = this.props
    return <Html name="signup-complete" data={user} />
  }
}

const mapStateToProps = (state) => {
  const { user, auth } = state

  return {
    user,
    auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMount: (auth) => {
      dispatch(requestSendSignupEmail(auth))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EndPage)
