import { useTranslation } from 'react-i18next'
import { Paper, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

const Panel = ({ label, n }) => (
  <Paper elevation={0} sx={{ backgroundColor: grey[100] }}>
    <Stack justifyContent="center" alignItems="center" sx={{ width: 80 }}>
      <Typography
        sx={{
          fontSize: '3em',
          fontWeight: 'bold',
          textAlign: 'center',
          color: grey[700],
        }}
      >
        {n}
      </Typography>
      <Typography variant="overline">{label}</Typography>
    </Stack>
  </Paper>
)

const Countdown = ({ milliseconds }) => {
  const { t } = useTranslation()
  if (!milliseconds) return false

  var sec = Math.floor((milliseconds / 1000) % 60),
    minutes = Math.floor((milliseconds / (1000 * 60)) % 60),
    hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24),
    days = Math.floor(milliseconds / (1000 * 60 * 60 * 24))

  return (
    <Stack direction="row" spacing={1}>
      <Panel label={t('Days')} n={days} />
      <Panel label={t('Hours')} n={hours} />
      <Panel label={t('Minutes')} n={minutes} />
      <Panel label={t('Seconds')} n={sec} />
    </Stack>
  )
}

export default Countdown
