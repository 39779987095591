import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from '../locales/en/translation.json'
import fi from '../locales/fi/translation.json'
import sv from '../locales/sv/translation.json'

/*
 * Docs:
 * https://react.i18next.com/
 * https://github.com/i18next/i18next-browser-languageDetector
 */
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      fi: {
        translation: fi,
      },
      sv: {
        translation: sv,
      },
    },
    detection: {
      order: ['querystring', 'localStorage'],
    },
    fallbackLng: 'sv',
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export const t = i18next.t

export default i18next
