import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { LoadingButton } from '@mui/lab'
import { getLocation, getReferrer } from './utils'

const FormWidget = ({
  onOpenPrivacyPolicy,
  projectId,
  propertyId,
  onSuccess,
  onError,
  token,
}) => {
  const { t } = useTranslation()

  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [birthYear, setBirthYear] = useState('')
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false)

  const [loading, setLoading] = useState(false)

  const [fullNameError, setFullNameError] = useState()
  const [emailError, setEmailError] = useState()
  const [phoneError, setPhoneError] = useState()
  const [postalCodeError, setPostalCodeError] = useState()
  const [birthYearError, setBirthYearError] = useState()
  const [acceptPrivacyPolicyError, setAcceptPrivacyPolicyError] = useState()

  const resetState = () => {
    setFullName('')
    setEmail('')
    setPhone('')
    setPostalCode('')
    setBirthYear('')
    setAcceptPrivacyPolicy(false)

    setFullNameError(false)
    setEmailError(false)
    setPhoneError(false)
    setPostalCodeError(false)
    setBirthYearError(false)
    setAcceptPrivacyPolicyError(false)

    setLoading(false)
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()

    if (!acceptPrivacyPolicy) {
      setAcceptPrivacyPolicyError(true)
      return
    }

    setLoading(true)

    const payload = {
      fullName,
      email,
      phone,
      zip: postalCode,
      birthYear,
      acceptPrivacyPolicy,
      projectId,
      location: getLocation(document),
      referrer: getReferrer(document),
    }
    if (propertyId) {
      payload.propertyId = propertyId
    }

    try {
      const response = await fetch('/api/v1/interest-signups', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })

      setLoading(false)

      if (response.status >= 500) {
        throw new Error(`HTTP status ${response.status}`)
      }

      const data = await response.json()

      if (data?.message?.fullName) {
        setFullNameError(true)
      }
      if (data?.message?.email) {
        setEmailError(true)
      }
      if (data?.message?.phone) {
        setPhoneError(true)
      }
      if (data?.message?.postalCode) {
        setPostalCodeError(true)
      }
      if (data?.message?.birthYear) {
        setBirthYearError(true)
      }
      if (data?.message?.acceptprivacyPolicy) {
        setAcceptPrivacyPolicyError(true)
      }

      if (data?.fullName) {
        resetState()
        onSuccess()
      }
    } catch (error) {
      // Network error or json parse error
      console.log(error)
      onError(error)
    }
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={t('Name')}
          name="name"
          onChange={(ev) => {
            setFullName(ev.target.value)
          }}
          value={fullName}
          helperText={fullNameError && t('Enter name')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label={t('Email address')}
          name="email"
          onChange={(ev) => {
            setEmail(ev.target.value)
          }}
          value={email}
          helperText={emailError && t('Enter email address')}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label={t('Phone number')}
          name="tel"
          onChange={(ev) => {
            setPhone(ev.target.value)
          }}
          value={phone}
          helperText={phoneError && t('Enter phone number')}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label={t('Postal code')}
          name="postal-code"
          onChange={(ev) => {
            setPostalCode(ev.target.value)
          }}
          value={postalCode}
          helperText={postalCodeError && t('Enter postal code')}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label={t('Year of birth')}
          name="bday-year"
          onChange={(ev) => {
            setBirthYear(ev.target.value)
          }}
          value={birthYear}
          helperText={birthYearError && t('Enter year of birth (yyyy)')}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ p: 2, backgroundColor: grey[100] }}
        >
          <FormControl>
            <FormControlLabel
              checked={acceptPrivacyPolicy}
              onChange={(ev) => {
                setAcceptPrivacyPolicy(ev.target.checked)
              }}
              control={<Checkbox />}
              label={t(
                'I have read the information about how my personal data is processed'
              )}
            />
            <FormHelperText>
              {acceptPrivacyPolicyError &&
                t('Please accept our personal data policy')}
            </FormHelperText>
          </FormControl>
          <Button onClick={onOpenPrivacyPolicy}>{t('Show')}</Button>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={4}>
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
        >
          <span>{t('Send')}</span>
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
export default FormWidget
