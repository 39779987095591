import SignupForm from './signupForm'
import SignupConfirmation from './signupConfirmation'

const SignupComponent = ({ isFetching, hasSignedUp, signup, onSubmit }) => (
  <div>
    {hasSignedUp && <SignupConfirmation signup={signup} />}
    {!hasSignedUp && <SignupForm isFetching={isFetching} onSubmit={onSubmit} />}
  </div>
)

export default SignupComponent
