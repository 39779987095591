import { Component } from 'react'
import { connect } from 'react-redux'
import LayoutComponent from './layoutComponent'
import Plausible from './plausible'
import FacebookPixel from './facebookPixel'

class Layout extends Component {
  render() {
    return (
      <>
        <LayoutComponent {...this.props} />
        <Plausible />
        <FacebookPixel />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { company, project } = state
  return {
    company,
    project,
  }
}

export default connect(mapStateToProps)(Layout)
