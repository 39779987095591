/**
 * Return the `token` search param in the document's URL
 * @param {object} doc A document
 * @returns {string} The `token` search param
 */
export const getToken = (doc) => {
  return new URL(doc.location)?.searchParams?.get('token')
}

/**
 * Return the `location` search param in the document's URL
 * @param {object} doc A document
 * @returns {string} The `location` search param
 */
export const getLocation = (doc) => {
  return new URL(doc.location)?.searchParams?.get('location')
}

/**
 * Return the `referrer` search param in the document's URL
 * @param {object} doc A document
 * @returns {string} The `referrer` search param
 */
export const getReferrer = (doc) => {
  return new URL(doc.location)?.searchParams?.get('referrer')
}

/**
 * Return the `propertyId` search param in the document's URL
 * @param {object} doc A document
 * @returns {string} The `propertyId` search param
 */
export const getPropertyId = (doc) => {
  return new URL(doc.location)?.searchParams?.get('propertyId')
}
