import { all, call } from 'redux-saga/effects'
import { sagas as companySagas } from './company'
import { sagas as emailSagas } from './email'
import { sagas as eventSignupSagas } from './eventSignup'
import { sagas as fellowSagas } from './fellow'
import { sagas as htmlSagas } from './html'
import { sagas as projectSagas } from './project'
import { sagas as propertySagas } from './property'
import { sagas as userSagas } from './user'
import { sagas as interestSagas } from './interest'
import { sagas as showingSagas } from './showing'
import { sagas as showingSignupSagas } from './showingSignup'

export default function* () {
  yield all([
    call(companySagas),
    call(emailSagas),
    call(eventSignupSagas),
    call(fellowSagas),
    call(htmlSagas),
    call(projectSagas),
    call(propertySagas),
    call(userSagas),
    call(interestSagas),
    call(showingSagas),
    call(showingSignupSagas),
  ])
}
