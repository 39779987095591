import { createAction } from 'redux-actions'
import { request } from '../../api'
import { put, takeLatest } from 'redux-saga/effects'

export const REQUEST_CREATE_EVENT_SIGNUP = 'Request event signup'
export const RECEIVE_CREATE_EVENT_SIGNUP = 'Receive event signup'

export const requestCreateEventSignup = createAction(
  REQUEST_CREATE_EVENT_SIGNUP,
  (projectId, signup) => ({ projectId, signup })
)
export const receiveCreateEventSignup = createAction(
  RECEIVE_CREATE_EVENT_SIGNUP
)

function* sagaCreateEventSignup({ payload }) {
  try {
    const data = yield request({
      method: 'POST',
      url: `/api/user/projects/${payload.projectId}/event-signup`,
      body: payload.signup,
    })
    yield put(receiveCreateEventSignup(data))
  } catch (error) {
    console.error(error)
    yield put(receiveCreateEventSignup(error))
  }
}

export function* sagas() {
  yield takeLatest(requestCreateEventSignup, sagaCreateEventSignup)
}

const initialState = {
  isFetching: false,
  hasSignedUp: false,
  signup: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    attendeeCount: 1,
  },
}

const reducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case REQUEST_CREATE_EVENT_SIGNUP:
      return { ...state, isFetching: true }
    case RECEIVE_CREATE_EVENT_SIGNUP:
      if (error) return { ...state, isFetching: false }
      return { isFetching: false, hasSignedUp: true, signup: payload }
    default:
      return state
  }
}

export default reducer
